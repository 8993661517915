body {
	text-align: center;
	padding: 8;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-grow: 0;
	align-items: center;
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	background-color: rgb(30, 32, 33);
	overflow: auto;
	/* background-image: url("lib/images/wallpaper.png"); */
}

*{
  margin:0;
  padding:0;
  box-sizing: content;
}

main {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	min-height:100%;
	padding: 1.4rem;
	gap: 2rem;
	border: 15px double rgb(121, 115, 103);
}

.clock-face{
	min-width: 275;
	height: 275;
	min-height: 275;
	width: 275;
}
